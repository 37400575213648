import request from '@/utils/Request';

// 批量下载证书
export function batchDownloadCertificates(ids, token) {
  const url = `/certificate/admin/datalist/download/batchdown?ids_str=${ids}&auth_token=${token}`;
  window.open(url, '_blank');
}

// 证书最大下载数量
export function getCertificateMaxDownloadCount() {
  return request({
    url: '/certificate/admin/datalist/download/maxnum',
    method: 'get',
  });
}

// 修改证书图片
export function updateCertificateFile(data) {
  return request({
    url: '/certificate/admin/batchImport/updCertificateFile',
    method: 'post',
    data,
  });
}

// 已有证书编码关联的岗位
export function getCertificateJobList() {
  return request({
    url: '/manage/admin/select/certificateJobs',
    method: 'get',
  });
}
