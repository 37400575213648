<template>
  <div class="certificate-overview-index ph-20">
    <Query :loading="loading"
           :downloading="downloading"
           @search="onSearch"
           @reset="onReset"
           @export="onExport"
           @export-excel="onExportExcel"/>
    <TabDetailPage ref="tabRef" :menus="menus"/>
  </div>
</template>

<script>
import {
  getCertificateMaxDownloadCount,
} from './api';

import Query from './Query.vue';
import TabDetailPage from '@/components/layout/TabDetailPage.vue';

export default {
  name: 'Index',
  components: {
    Query,
    TabDetailPage,
  },
  watch: {
    '$route.path'() {
      this.updateMaxDownloadCount();
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      maxDownloadCount: 20,

      menus: [{
        id: '2',
        title: '线上任务证书',
        path: `./online`,
      }, {
        id: '1',
        title: '线下培训证书',
        path: `./offline`,
      }],
    };
  },
  created() {
    this.getCertificateMaxDownloadCount();
  },
  methods: {
    onSearch(evt) {
      if (this.$refs.tabRef?.$refs?.detailRef?.onSearch) {
        this.$refs.tabRef.$refs.detailRef.onSearch(evt);
      }
    },
    onReset(evt) {
      if (this.$refs.tabRef?.$refs?.detailRef?.onReset) {
        this.$refs.tabRef.$refs.detailRef.onReset(evt);
      }
    },
    onExport(evt) {
      if (this.$refs.tabRef?.$refs?.detailRef?.onExport) {
        this.$refs.tabRef.$refs.detailRef.onExport(evt);
      }
    },
    onExportExcel(evt) {
      if (this.$refs.tabRef?.$refs?.detailRef?.onExportExcel) {
        this.$refs.tabRef.$refs.detailRef.onExportExcel(evt);
      }
    },

    async getCertificateMaxDownloadCount() {
      const data = await getCertificateMaxDownloadCount();
      this.maxDownloadCount = data?.data?.download_max_num || 20;

      this.updateMaxDownloadCount();
    },
    updateMaxDownloadCount() {
      if (this.$refs.tabRef?.$refs?.detailRef) {
        this.$refs.tabRef.$refs.detailRef.maxDownloadCount = this.maxDownloadCount;
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
