<template>
  <a-modal title="导入证书"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="true"
           :destroy-on-close="true"
           :width="600"
           :centered="true"
           ok-text="提交"
           @ok="confirm"
           @cancel="close()">
    <a-form :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }">
      <a-form-item label="选择证书图片">
        <a-upload name="file"
                  action="/public/conf/upload"
                  :headers="{ 'X-Auth-Token': $store.state.User.token || '' }"
                  :file-list="fileList"
                  :remove="removeFile"
                  accept="image/*"
                  list-type="picture-card"
                  :multiple="false"
                  @change="uploadChange">
          <div v-if="!fileList || !fileList.length">
            <a-icon type="plus"/>
            <a-button type="link">上传图片</a-button>
          </div>
        </a-upload>
      </a-form-item>

      <a-form-item label="证书图片">
        <img v-if="detail.file_data && detail.file_data.url"
             :src="detail.file_data.url"
             alt=""
             style="max-width: 200px; max-height: 200px;"
             @click="previewImage">
      </a-form-item>

      <a-form-item v-for="(column) in parsedColumns"
                   :key="column.key"
                   :label="column.title">
        <a-input :value="getModel(column)" :disabled="true"/>
      </a-form-item>
    </a-form>

    <ImageViewer ref="imageViewerRef"/>
  </a-modal>
</template>

<script>
import { updateCertificateFile } from '../api';
import ImageViewer from '@/components/ImageViewer.vue';

export default {
  name: 'ModifyCertificate',
  components: {
    ImageViewer,
  },
  props: {
    columns: { type: Array, default: () => ([]) },
  },
  computed: {
    parsedColumns() {
      return this.columns.filter((i) => !['id', 'action'].includes(i.key));
    },
    getModel() {
      return (column) => {
        if (column.key.indexOf('.') > -1) {
          return this.detail[column.key.split('.')[0]]?.[column.key.split('.')[1]];
        } else {
          return this.detail[column.key];
        }
      };
    },
  },
  data() {
    return {
      loading: false,
      shown: false,

      fileList: [],

      detail: {},
    };
  },
  methods: {
    show(detail) {
      this.detail = detail || {};
      this.fileList = [];
      // this.fileList = [{
      //   uid: detail.file_data?.id,
      //   name: detail.file_data?.down_title,
      //   url: detail.file_data?.url,
      //   status: 'done',
      //   file_resource_id: detail.file_data?.id,
      // }];

      this.shown = true;
    },
    close() {
      this.shown = false;
    },

    confirm() {
      if (!this.fileList?.[0]?.url) {
        this.$message.info('请上传图片');
        return;
      }

      this.save();
    },
    async save() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        id: this.detail.id,
        file_id: this.fileList[0]?.file_resource_id,
      };
      const data = await updateCertificateFile(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '上传失败');
        return;
      }
      this.$message.success(data?.message || '上传成功');
      this.$emit('success');

      this.close();
    },

    previewImage() {
      if (!this.detail?.file_data?.url) {
        return;
      }
      if (this.$refs.imageViewerRef?.show) {
        this.$refs.imageViewerRef.show({ url: this.detail?.file_data?.url || '' });
      }
    },

    removeFile() {
      this.fileList = [];
    },
    uploadChange(evt) {
      switch (evt?.file?.status) {
        case 'uploading':
          this.fileList = [evt?.file];
          this.loading = true;
          break;
        case 'done':
          this.fileList = [evt?.file];
          this.loading = false;
          this.fileList.forEach((i) => {
            i.file_resource_id = i.response?.data?.file_resource_id;
            i.url = i.response?.data?.url;
          });
          break;
        case 'removed':
          this.fileList = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
