<template>
  <div class="tab-detail-page">
    <div class="flex space-between pd-card">
      <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
        <template v-for="item of menus">
          <a-menu-item v-if="!item.hidden" :key="item.id"
                       :disabled="!showMenus && item.disabled">
            {{ item.title }}
          </a-menu-item>
        </template>
      </a-menu>

      <slot name="extra"/>
    </div>

    <router-view ref="detailRef"/>
  </div>
</template>

<script>
export default {
  name: 'TabDetailPage',
  props: {
    showMenus: { type: Boolean, default: false },
    menus: {
      type: Array,
      default: () => [],
      validator: (menus) => menus && menus.every((menu) => ['id', 'title', 'path'].every((key) => !!menu[key])),
    },
    query: { type: Object, default: () => ({}) },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.executeSelection(this.$route.path);
      },
    },
  },
  data() {
    return {
      selectedMenu: this.menus[0]?.id ? [this.menus[0].id] : [],
    };
  },
  methods: {
    executeSelection(path) {
      this.menus.forEach(menu => {
        if (menu.path?.startsWith('/') && menu.path === path) {
          this.selectedMenu = [menu.id];
        } else if (
          menu.path?.startsWith('./')
          && new RegExp(menu.path?.slice(2) + '$', 'g').test(path)
        ) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({ key }) {
      const path = this.getPath(key);
      this.$router.replace({
        path,
        query: this.query,
      });
    },
    getPath(key) {
      const [{ path }] = this.menus.filter(menu => `${menu.id}` === `${key}` && menu.path);
      return path || '';
    },
  },
};
</script>

<style scoped lang="scss">

</style>
